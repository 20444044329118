<template>
  <v-card id="graphic-design-xs" width="95%" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE GRAPHIC DESIGN</h1>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <h2>ออกแบบกราฟฟิกดีไซน์ที่ใช้ในการโฆษณา</h2>
      <p>&emsp; Graphic Design เป็นหนึ่งในเครื่องมือในการโฆษณาสินค้า
        เพื่อกระตุ้นให้ลูกค้าเกิดความต้องการที่จะซื้อสินค้าหรือบริการมากขึ้น
        ภาพโฆษณาจึงไม่เป็นเพียงแต่ภาพที่แสดงข้อความเท่านั้น ต้องเป็นการออกแบบที่ผ่านการคิดมาอย่างรอบคอบ
        เพื่อให้ได้ประสิทธิภาพสูงสุด เพราะฉะนั้นการออกแบบจึงสำคัญมากในยุคนี้</p>

      <carousel-3d :border="0" width="250" height="200">
        <slide :index="0">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fgraphic%20design1.jpg?alt=media&token=6ee8f4f3-e673-4f36-9c92-430e267aa6a9">
        </slide>
        <slide :index="1">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fgraphic%20design2.jpg?alt=media&token=f6364859-2ff9-4f3d-8869-719af66a7f29">
        </slide>
        <slide :index="2">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fgraphic%20design3.jpg?alt=media&token=7f385531-000e-4799-b46c-9d09f4048ce6">
        </slide>
      </carousel-3d>
    </v-card-text>
  </v-card>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  components: {
    Carousel3d,
    Slide
  }
}
</script>

<style>
#graphic-design-xs {
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  overflow-y: auto;
  max-height: 100vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
#graphic-design-xs h1 {
  font-size: 1.3em;
  line-height: 100%;
  letter-spacing: -1px;
}
#graphic-design-xs h2 {
  font-size: 1.2em;
  line-height: 100%;
  font-weight: 400;
  color: #424242;
}
#graphic-design-xs p {
  font-size: 0.9em;
  color: #757575;
  font-weight: 300;
  margin-top: 20px;
}
#graphic-design-xs img {
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
