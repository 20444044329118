<template>
  <v-flex id="slide_bg_home1_xs">
    <div v-show="false">
      <img src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/Banners%2F1.banner.jpg?alt=media&token=794ee763-05bc-447e-b499-c18d87521267" />
      <img src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/Banners%2F2.banner.jpg?alt=media&token=bd8ff6eb-8482-439c-b4e5-3d4eccdecc34" />
      <img src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/Banners%2F3.banner.jpg?alt=media&token=e1f437dc-2915-4fd9-8b11-bb1fbde7d4b5" />
    </div>

    <v-layout style="width: 100%" fill-height column justify-center align-center>
      <h1 class="text-white">25 Second Creative</h1>
      <h4 class="text-white text-center"></h4>
      <v-btn :to="{ name: 'contact-us' }" class="px-4 pb-1 pt-2" outlined color="white" small>Contact us</v-btn>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  }
}
</script>

<style>
#center {
  background: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
#slide_bg_home1_xs .text-white {
  color: white;
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
                0px 8px 13px rgba(0,0,0,0.1),
                0px 18px 23px rgba(0,0,0,0.1);
}
#slide_bg_home1_xs {
  width: 100%;
  height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  animation: slidebg 15s linear infinite 0s;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/Banners%2F1.banner.jpg?alt=media&token=794ee763-05bc-447e-b499-c18d87521267')
}
#slide_bg_home1_xs .v-btn {
  height: auto;
}
@keyframes slidebg {
  0% {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/Banners%2F1.banner.jpg?alt=media&token=794ee763-05bc-447e-b499-c18d87521267');
  }
  33% {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/Banners%2F1.banner.jpg?alt=media&token=794ee763-05bc-447e-b499-c18d87521267');
  }
  35% {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/Banners%2F2.banner.jpg?alt=media&token=bd8ff6eb-8482-439c-b4e5-3d4eccdecc34');
  }
  66% {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/Banners%2F2.banner.jpg?alt=media&token=bd8ff6eb-8482-439c-b4e5-3d4eccdecc34');
  }
  68% {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/Banners%2F3.banner.jpg?alt=media&token=e1f437dc-2915-4fd9-8b11-bb1fbde7d4b5');
  }
  98% {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/Banners%2F3.banner.jpg?alt=media&token=e1f437dc-2915-4fd9-8b11-bb1fbde7d4b5');
  }
}

h4::before {
    content: 'รับออกแบบสื่อโฆษณาออนไลน์และออฟไลน์';
    animation-name: head2;
    animation-duration: 15s;
    animation-iteration-count: infinite;
}

@keyframes head2 {
  0% {
    content: "รับออกแบบสื่อโฆษณาออนไลน์และออฟไลน์"
  }
  33% {
    content: "รับออกแบบสื่อโฆษณาออนไลน์และออฟไลน์"
  }
  35% {
    content: "รับสร้างแบรนด์ ออกแบบโลโก้ออกแบบ CI ฉลาก ตราสินค้า นามบัตร"
  }
  66% {
    content: "รับสร้างแบรนด์ ออกแบบโลโก้ออกแบบ CI ฉลาก ตราสินค้า นามบัตร"
  }
  68% {
    content: "รับทำคอนเทนต์ เขียนบทความ Content Marketing"
  }
  98% {
    content: "รับทำคอนเทนต์ เขียนบทความ Content Marketing"
  }
}
#slide_bg_home1_xs h1 {
  font-size: 2em;
  font-weight: 500;
  line-height: 100%;
}
#slide_bg_home1_xs h4 {
  font-size: 0.8em;
  font-weight: 500;
  line-height: 4em;
}
</style>
