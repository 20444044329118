<template>
  <v-flex id="slide_bg_home2">
    <v-layout style="width: 100%; margin-left: auto;" fill-height column justify-center align-center>
      <p class="text-center" :class="width >= 1150 ? 'rotate' : 'not-rotate'">WHAT IS 25 SECONDS CREATIVE ?</p>
      <p>25 Seconds Creative เราพร้อมผลิตเครื่องมือทางการตลาดและการประชาสัมพันธ์ที่มีคุณภาพใน</p>
      <p>ราคาที่จับต้องได้ให้กับคุณ เพื่อสร้างประสบการณ์ที่ดีระหว่างแบรนด์กับผู้บริโภค ไม่ว่าจะเป็น</p>
      <v-card flat width="1000px" style="background: transparent" class="mt-5">
        <v-layout justify-space-around>
          <v-layout column align-center>
            <img height="40px" src="/icon/idea.svg">
            <p class="mt-2 body-1">BRAND IDENTITY</p>
          </v-layout>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-web.svg">
            <p class="mt-2 body-1">WEB DESIGN</p>
          </v-layout>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-app.svg">
            <p class="mt-2 body-1">APPLICATION DESIGN</p>
          </v-layout>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-content.svg">
            <p class="mt-2 body-1">CONTENT EDITOR</p>
          </v-layout>
        </v-layout>
        <v-layout class="mt-5" justify-space-around>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-graphic.svg">
            <p class="mt-2 body-1">GRAPHIC DESIGN</p>
          </v-layout>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-photo.svg">
            <p class="mt-2 body-1">PRODUCT PHOtOGRAPHY</p>
          </v-layout>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-vdo.svg">
            <p class="mt-2 body-1">VDO PRODUCTION</p>
          </v-layout>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-alldesign.svg">
            <p class="mt-2 body-1">ALL DESIGNS</p>
          </v-layout>
        </v-layout>
      </v-card>
      <p class="mt-5">เพราะในปัจจุบันการตลาดและการประชาสัมพันธ์ถือเป็นปัจจัยหลักในการเติบโตของธุรกิจ เป็นส่วนหนึ่งในเครื่องมือที่ใช้</p>
      <p>สื่อสารทั้งภายในและภายนอกองค์กร เราจึงพร้อมสนับสนุนธุรกิจของคุณ โดยเฉพาะกลุ่มธุรกิจ Startup และ SME</p>
      <p>เพื่อลดต้นทุนในการผลิตเครื่องมือการตลาด การจ้างบุคลากรทางด้านนี้หรือ การใช้บริการบริษัทโฆษณาทั่วไปที่มีการบริการ</p>
      <p>ที่สูงและรับแค่กลุ่มธุรกิจใหญ่เท่านั้น 25 Second Creative เราเป็นหนึ่งในธุรกิจ Startup มาก่อน เราจึง</p>
      <p>เข้าใจถึงปัญหาเหล่านี้เราคุณและพร้อมสนับสนุนธุรกิจของคุณให้เติบโต</p>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  data () {
    return {
      width: 0,
      height: 0
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.width = window.innerWidth
      this.height = window.innerHeight
    }
  },
  components: {
  }
}
</script>

<style>
#slide_bg_home2 {
  width: 100%;
  position: relative;
  height: 100vh;
  background-color: #EC4D37;
}
#slide_bg_home2 p {
  margin-bottom: 0.5em;
  font-size: 1.25em;
  font-weight: 300;
  color: white;
}
#slide_bg_home2 .rotate {
  transform: rotate(-90deg) translate(-50%, -50%);
  position: absolute;
  transform-origin: top left;
  clear: both;
  float: left;
  font-weight: 500;
  width: 100vh;
  top: 50%;
  left: 7%;
  font-size: 2em;
}
#slide_bg_home2 .not-rotate {
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 5%;
}
</style>
