<template>
  <v-flex id="slide_bg_home2_xs">
    <v-layout style="width: 100%; padding-top: 10vh;" class="px-5" column align-center>
      <h1 class="text-center">WHAT IS 25 SECONDS CREATIVE ?</h1>
      <p class="mt-3">&emsp;&emsp; 25 Seconds Creative เราพร้อมผลิตเครื่องมือทางการตลาดและการประชาสัมพันธ์ที่มีคุณภาพใน ราคาที่จับต้องได้ให้กับคุณ เพื่อสร้างประสบการณ์ที่ดีระหว่างแบรนด์กับผู้บริโภค ไม่ว่าจะเป็น</p>
      <v-card flat width="300px" style="background: transparent" class="mt-5">
        <v-layout justify-space-around>
          <v-layout column align-center>
            <img height="40px" src="/icon/idea.svg">
            <p class="mt-2 caption">BRAND IDENTITY</p>
          </v-layout>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-web.svg">
            <p class="mt-2 caption">WEB DESIGN</p>
          </v-layout>
        </v-layout>
        <v-layout class="mt-5" justify-space-around>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-app.svg">
            <p class="mt-2 caption">APPLICATION DESIGN</p>
          </v-layout>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-content.svg">
            <p class="mt-2 caption">CONTENT EDITOR</p>
          </v-layout>
        </v-layout>
        <v-layout class="mt-5" justify-space-around>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-graphic.svg">
            <p class="mt-2 caption">BRAND IDENTITY</p>
          </v-layout>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-photo.svg">
            <p class="mt-2 caption">WEB DESIGN</p>
          </v-layout>
        </v-layout>
        <v-layout class="mt-5" justify-space-around>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-vdo.svg">
            <p class="mt-2 caption">APPLICATION DESIGN</p>
          </v-layout>
          <v-layout column align-center>
            <img height="40px" src="/icon/white-alldesign.svg">
            <p class="mt-2 caption">CONTENT EDITOR</p>
          </v-layout>
        </v-layout>
      </v-card>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  }
}
</script>

<style>
#slide_bg_home2_xs {
  width: 100%;
  position: relative;
  /* height: 100vh; */
  background-color: #EC4D37;
}
#slide_bg_home2_xs h1 {
  font-size: 1.5em;
  font-weight: 500;
  color: white;
  line-height: 200%;
  margin-bottom: 5%;
}
#slide_bg_home2_xs p {
  margin-bottom: 0;
  font-size: 1em;
  font-weight: 300;
  color: white;
}
</style>
