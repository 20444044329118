<template>
  <v-app>
    <Toolbar :dialog="dialog" @openDialog="openDialog"/>
    <Menu :dialog="dialog" @openDialog="openDialog"/>

    <v-content>
      <transition name="page-fade">
        <router-view/>
    </transition>
    </v-content>
  </v-app>
</template>

<script>
import Menu from '@/components/Menu'
import Toolbar from '@/components/Toolbar'
export default {
  name: 'App',
  components: {
    Toolbar,
    Menu
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    openDialog () {
      this.dialog = !this.dialog
    }
  },
  mounted () {
    document.documentElement.style.overflow = 'hidden'
    this.$store.dispatch('fetchWorkfolio')
  }
}
</script>

<style>
@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/HelveticaNeue-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/HelveticaNeue-Roman.ttf') format('truetype');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/HelveticaNeue-MediumCond.ttf') format('truetype');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/HelveticaNeue-CondensedBold.ttf') format('truetype');
}
/* thai */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Prompt Light'), local('Prompt-Light'), url(https://fonts.gstatic.com/s/prompt/v3/-W_8XJnvUD7dzB2Cy_gIfWMuQ5Q.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Prompt'), local('Prompt-Regular'), url(https://fonts.gstatic.com/s/prompt/v3/-W__XJnvUD7dzB2KdNodVkI.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Prompt Medium'), local('Prompt-Medium'), url(https://fonts.gstatic.com/s/prompt/v3/-W_8XJnvUD7dzB2Ck_kIfWMuQ5Q.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Prompt Bold'), local('Prompt-Bold'), url(https://fonts.gstatic.com/s/prompt/v3/-W_8XJnvUD7dzB2C2_8IfWMuQ5Q.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
* {
  font-family: "Helvetica Neue", "Prompt";
}
h1 {
  font-size: 85px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 80px;
}
h2 {
  font-size: 70px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 80px;
}
h3 {
  font-size: 50px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 80px;
}
h4 {
  font-size: 30px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 80px;
}
h5 {
  font-size: 30px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 80px;
}
h6 {
  font-size: 80px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 80px;
}
.page-fade-enter-active {
  transition: all .2s ease;
}
.page-fade-leave-active {
  transition: all .2s ease;
}
.page-fade-enter, .page-fade-leave-to {
  opacity: 0;
}
</style>
