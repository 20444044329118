import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/index.html',
      component: () => import('./views/Home.vue'),
      name: 'index',
      alias: '/'
    },
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue')
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('./views/ContactUs.vue')
    },
    {
      path: '/our-services',
      name: 'our-services',
      component: () => import('./views/OurServices.vue')
    },
    {
      path: '/brand-identity',
      name: 'brand-identity',
      component: () => import('./views/BrandIdentity.vue')
    },
    {
      path: '/website-design',
      name: 'website-design',
      component: () => import('./views/WebsiteDesign.vue')
    },
    {
      path: '/application-design',
      name: 'application-design',
      component: () => import('./views/ApplicationDesign.vue')
    },
    {
      path: '/content-editor',
      name: 'content-editor',
      component: () => import('./views/ContentEditor.vue')
    },
    {
      path: '/graphic-design',
      name: 'graphic-design',
      component: () => import('./views/GraphicDesign.vue')
    },
    {
      path: '/product-photography',
      name: 'product-photography',
      component: () => import('./views/ProductPhotography.vue')
    },
    {
      path: '/video-production',
      name: 'video-production',
      component: () => import('./views/VideoProduction.vue')
    },
    {
      path: '/all-designs',
      name: 'all-designs',
      component: () => import('./views/AllDesigns.vue')
    },
    {
      path: '/workfolio',
      name: 'workfolio',
      component: () => import('./views/Workfolio.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
