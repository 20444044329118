<template>
  <v-card id="product-photography-xs" width="95%" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE PRODUCT PHOTOGRAPHY</h1>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <h2>บริการรับถ่ายภาพสินค้า</h2>
      <p>&emsp; Product Photography เป็นหนึ่งในเครื่องมือที่ให้โฆษณาสินค้า สามารถนำไปใช้ได้ในทุกแบบ
        ทั้งทำเป็นสื่อโฆษณา เป็นภาพเป็นประกอบใน website Application เป็นต้น เป็นภาพประกอบในการทำ
        Content ทำเป็นรูป Profile หรือ รูป Cover และอื่นๆ
        เรียกได้ว่าถ่ายภาพเพียงครั้งเดียวสามารถนำมาใช้ได้ในระยะยาว</p>

      <carousel-3d :border="0" width="250" height="200">
        <slide :index="0">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fproduct%20photography1.jpg?alt=media&token=a91a0546-8dd8-4c00-aeff-a5b98f2b8c00">
        </slide>
        <slide :index="1">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fproduct%20photography2.jpg?alt=media&token=ca093f49-bd8f-4e8a-bf7b-f3781970b93c">
        </slide>
        <slide :index="2">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fproduct%20photography3.jpg?alt=media&token=9dfba236-fb1b-45c3-bc01-75aac4d67977">
        </slide>
      </carousel-3d>
    </v-card-text>
  </v-card>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  components: {
    Carousel3d,
    Slide
  }
}
</script>

<style>
#product-photography-xs {
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  overflow-y: auto;
  max-height: 100vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
#product-photography-xs h1 {
  font-size: 1.3em;
  line-height: 100%;
  letter-spacing: -1px;
}
#product-photography-xs h2 {
  font-size: 1.2em;
  line-height: 100%;
  font-weight: 400;
  color: #424242;
}
#product-photography-xs p {
  font-size: 0.9em;
  color: #757575;
  font-weight: 300;
  margin-top: 20px;
}
#product-photography-xs img {
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
