<template>
  <v-card id="brand-identity-xs" width="95%" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE BRAND IDENTITY</h1>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <h2>ออกแบบและสร้างแบรนด์</h2>
      <p>&emsp; ในยุคที่ธุรกิจเกิดขึ้นมามากมายเป็นหมื่นเป็นล้านธุรกิจ ใครๆก็สามารถหาช่องทางทำธุรกิจได้อย่างง่ายดาย
        โดยเฉพาะในโลกออนไลน์ แต่มีเพียงไม่กี่ธุรกิจที่อยู่รอด สามารถสร้างยอดขายได้มากมายและสามารถทำให้ลูกค้าจดจำได้
        ซึ่งเป็นสิ่งเจ้าของธุรกิจต้องให้ความสำคัญเป็นอันดับแรกในการเริ่มทำธุรกิจ เพราะนั่นคือ "การสร้างแบรนด์"
        เป็นหนึ่งกลยุทธ์สำคัญในการสร้างตัวตนของธุรกิจคุณในสายตาของลูกค้า ว่าจะให้ลูกค้ามองคุณยังไง ขายอะไร
        และจดจำคุณในแบบไหน รวมถึงสร้างข้อได้เปรียบให้เหนือคู่แข่ง
        สร้างภาพลักษณ์ที่ดีและสร้างความน่าเชื่อถือให้กับลูกค้าได้</p>

      <carousel-3d :border="0" width="250" height="200">
        <slide :index="0">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fbrand%20identity1.jpg?alt=media&token=ab7cdd42-e4af-4918-bc50-28210151d3c2">
        </slide>
        <slide :index="1">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fbrand%20identity2.jpg?alt=media&token=e7e7dcbb-66d3-4f91-8340-5f49128faf41">
        </slide>
        <slide :index="2">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fbrand%20identity3.jpg?alt=media&token=683d6e8e-3d4a-4f79-a86c-464e19cce6fd">
        </slide>
      </carousel-3d>
      <!-- <v-layout class="mt-6">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fbrand%20identity1.jpg?alt=media&token=ab7cdd42-e4af-4918-bc50-28210151d3c2">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fbrand%20identity2.jpg?alt=media&token=e7e7dcbb-66d3-4f91-8340-5f49128faf41">
        <img src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fbrand%20identity3.jpg?alt=media&token=683d6e8e-3d4a-4f79-a86c-464e19cce6fd">
      </v-layout> -->
    </v-card-text>
  </v-card>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  components: {
    Carousel3d,
    Slide
  }
}
</script>

<style>
#brand-identity-xs {
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  overflow-y: auto;
  max-height: 100vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
#brand-identity-xs h1 {
  font-size: 1.3em;
  line-height: 100%;
  letter-spacing: -1px;
}
#brand-identity-xs h2 {
  font-size: 1.2em;
  line-height: 100%;
  font-weight: 400;
  color: #424242;
}
#brand-identity-xs p {
  font-size: 0.9em;
  color: #757575;
  font-weight: 300;
  margin-top: 20px;
}
#brand-identity-xs img {
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
