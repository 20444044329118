<template>
  <transition name="slide-fade">
    <v-layout  v-if="dialog" class="elevation-1" id="menu">
      <v-card flat class="content">
        <router-link :to="{ name: 'our-services' }"><a :style="$vuetify.breakpoint.mdAndUp ? '' : 'font-size: 1.1em;'" @click="$emit('openDialog')">OUR SERVICES</a></router-link><br>
        <router-link :to="{ name: 'contact-us' }"><a :style="$vuetify.breakpoint.mdAndUp ? '' : 'font-size: 1.1em;'" @click="$emit('openDialog')">CONTACT US</a></router-link><br>
        <router-link :to="{ name: 'workfolio' }"><a :style="$vuetify.breakpoint.mdAndUp ? '' : 'font-size: 1.1em;'" @click="$emit('openDialog')">WORKFOLIO</a></router-link>
      </v-card>
    </v-layout>
  </transition>
</template>

<script>
export default {
  props: ['dialog'],
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style>
#menu {
  position: absolute;
  width: 100vw;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: white;
}
#menu .content {
  position: absolute;
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  left: 22%;
  top: 40%;
}
#menu .content a {
  font-weight: 500;
  font-size: 1.5em;
  line-height: 2em;
  text-decoration: none;
  color: black;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(100vw);
  opacity: 0;
}
</style>
