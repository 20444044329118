<template>
  <v-layout id="our-services" fill-height justify-center align-center>
    <v-card width="1000px" color="transparent" flat>
      <v-card-title>
        <v-layout wrap justify-space-between align-start>
          <h1 style="line-height: 100%; font-size: 2em;">OUR SERVICES</h1>
          <p class="mb-0 p-title">ในปัจจุบันการตลาดและการประชาสัมพันธ์ถือเป็นปัจจัยหลักในการเติบโตของธุรกิจ
            เป็นส่วนหนึ่งในเครื่องมือที่ใช้สื่อสารทั้งภายในและภายนอกองค์กร เราจึงพร้อมสนับสนุนธุรกิจของคุณโดยเฉพาะกลุ่มธุรกิจ
            Startup และ SME เพื่อลดต้นทุนในการผลิตเครื่องมือการตลาด
            การจ้างบุคลากรทางด้านนี้หรือการใช้บริการบริษัทโฆษณาทั่วไปที่มีการบริการที่สูงและรับแค่กลุ่มธุรกิจใหญ่เท่านั้น
            25 SecondCreative เราเป็นหนึ่งในธุรกิจ Startup
            มาก่อน เราจึงเข้าใจถึงปัญหาเหล่านี้เราคุณและพร้อมสนับสนุนธุรกิจของคุณให้เติบโต</p>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-layout class="card-services mt-12" justify-space-between>
          <v-hover>
            <v-card slot-scope="{ hover }">
              <v-layout fill-height column justify-center align-center>
                <img v-show="hover" height="50px" src="/icon/idea.svg">
                <img v-show="!hover" height="50px" src="/icon/orange-idea.svg">
                <h2>BRAND IDENTITY</h2>
                <p>ออกแบบและสร้างแบรนด์</p>
                <v-btn v-show="hover" :to="{ name: 'brand-identity' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
              </v-layout>
            </v-card>
          </v-hover>
          <v-hover>
            <v-card slot-scope="{ hover }">
              <v-layout fill-height column justify-center align-center>
                <img v-show="hover" height="50px" src="/icon/white-web.svg">
                <img v-show="!hover" height="50px" src="/icon/website.svg">
                <h2>WEBSITE DESIGN</h2>
                <p>ออกแบบ Website</p>
                <v-btn v-show="hover" :to="{ name: 'website-design' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
              </v-layout>
            </v-card>
          </v-hover>
          <v-hover>
            <v-card slot-scope="{ hover }">
              <v-layout fill-height column justify-center align-center>
                <img v-show="hover" height="50px" src="/icon/white-app.svg">
                <img v-show="!hover" height="50px" src="/icon/mobile.svg">
                <h2>APPLICATION DESIGN</h2>
                <p>ออกแบบ Application</p>
                <v-btn v-show="hover" :to="{ name: 'application-design' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
              </v-layout>
            </v-card>
          </v-hover>
          <v-hover>
            <v-card slot-scope="{ hover }">
              <v-layout fill-height column justify-center align-center>
                <img v-show="hover" height="50px" src="/icon/white-content.svg">
                <img v-show="!hover" height="50px" src="/icon/design.svg">
                <h2>CONTENT EDITOR</h2>
                <p>วางแผนและเขียน Content</p>
                <v-btn v-show="hover" :to="{ name: 'content-editor' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
              </v-layout>
            </v-card>
          </v-hover>
        </v-layout>
        <v-layout class="card-services mt-2" justify-space-between>
          <v-hover>
            <v-card slot-scope="{ hover }">
              <v-layout fill-height column justify-center align-center>
                <img v-show="hover" height="50px" src="/icon/white-graphic.svg">
                <img v-show="!hover" height="50px" src="/icon/computer.svg">
                <h2>GRAPHIC DESIGN</h2>
                <p>ออกแบบภาพที่ใช้ในการโฆษณา</p>
                <v-btn v-show="hover" :to="{ name: 'graphic-design' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
              </v-layout>
            </v-card>
          </v-hover>
          <v-hover>
            <v-card slot-scope="{ hover }">
              <v-layout fill-height column justify-center align-center>
                <img v-show="hover" height="50px" src="/icon/white-photo.svg">
                <img v-show="!hover" height="50px" src="/icon/polaroid-pictures.svg">
                <h2>PRODUCT PHOTOGRAPHY</h2>
                <p>บริการรับถ่ายภาพสินค้า</p>
                <v-btn v-show="hover" :to="{ name: 'product-photography' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
              </v-layout>
            </v-card>
          </v-hover>
          <v-hover>
            <v-card slot-scope="{ hover }">
              <v-layout fill-height column justify-center align-center>
                <img v-show="hover" height="50px" src="/icon/white-vdo.svg">
                <img v-show="!hover" height="50px" src="/icon/video-camera.svg">
                <h2>VIDEO PRODUCTION</h2>
                <p>ออกแบบและผลิต VDO Content</p>
                <v-btn v-show="hover" :to="{ name: 'video-production' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
              </v-layout>
            </v-card>
          </v-hover>
          <v-hover>
            <v-card slot-scope="{ hover }">
              <v-layout fill-height column justify-center align-center>
                <img v-show="hover" height="50px" src="/icon/white-alldesign.svg">
                <img v-show="!hover" height="50px" src="/icon/think.svg">
                <h2>ALL DESIGNS</h2>
                <p>ออกแบบสื่อโฆษณาอื่นๆ</p>
                <v-btn v-show="hover" :to="{ name: 'all-designs' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
              </v-layout>
            </v-card>
          </v-hover>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  components: {
  }
}
</script>

<style>
#our-services .p-title {
  font-size: 0.7em;
  color: #757575;
  line-height: 1.5em;
  width: 470px;
}
#our-services .card-services .v-card {
  width: 237px;
  height: 170px;
  border-radius: 7px;
  background-color: white;
}
#our-services .card-services .v-card:hover {
  width: 237px;
  height: 170px;
  border-radius: 7px;
  background-color: #EC4D37;
}
#our-services .card-services .v-card h2 {
  font-size: 1.3em;
  line-height: 100%;
  margin-top: 10px;
  margin-bottom: 3px;
  font-weight: 500;
  color: #EC4D37;
}
#our-services .card-services .v-card:hover h2 {
  color: white;
}
#our-services .card-services .v-card p {
  font-weight: 300;
  color: #EC4D37;
  margin-bottom: 0;
}
#our-services .card-services .v-card:hover p {
  color: white;
}
</style>
