import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'

import VueCarousel from 'vue-carousel'

import * as firebase from 'firebase/app'
import 'firebase/firestore'

Vue.use(VueCarousel)

const firebaseConfig = {
  apiKey: 'AIzaSyBMmFGGB2OIjRNpYTOlKPHrkkZLNm-ZSKE',
  authDomain: 'secondscreativeweb.firebaseapp.com',
  databaseURL: 'https://secondscreativeweb.firebaseio.com',
  projectId: 'secondscreativeweb',
  storageBucket: 'secondscreativeweb.appspot.com',
  messagingSenderId: '611127651934',
  appId: '1:611127651934:web:95c101875557209b'
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
