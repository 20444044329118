<template>
  <v-card id="all-designs-xs" width="95%" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE ALL DESIGNS</h1>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <h2>ออกแบบสื่อโฆษณาอื่นๆ</h2>
      <p>&emsp; ยังมี Design อีกมายมายที่เป็นหนึ่งในเครื่องมือในวัถตุประสงค์ต่างๆ ที่ 25 Seconds Creative
        พร้อมจะสนับสนุนคุณ เพราะเราคือที่สุดแห่งการออกแบบในราคาที่จับต้องได้</p>

      <carousel-3d :border="0" width="250" height="200">
        <slide :index="0">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FAll%20Design1.jpg?alt=media&token=64c8b952-72bc-446e-9d13-4456ffd4d7ab">
        </slide>
        <slide :index="1">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FAll%20Design2.jpg?alt=media&token=c3c35b92-8f1e-4b7a-b434-6218806d3f14">
        </slide>
        <slide :index="2">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FAll%20Design3.jpg?alt=media&token=ff09039a-ca5a-40cb-863d-662a9f53abf3">
        </slide>
        <slide :index="3">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FAll%20Design4.jpg?alt=media&token=71a212dc-e2ea-4cd3-93e1-4398039d58c1">
        </slide>
      </carousel-3d>
    </v-card-text>
  </v-card>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  components: {
    Carousel3d,
    Slide
  }
}
</script>

<style>
#all-designs-xs {
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  overflow-y: auto;
  max-height: 100vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
#all-designs-xs h1 {
  font-size: 1.3em;
  line-height: 100%;
  letter-spacing: -1px;
}
#all-designs-xs h2 {
  font-size: 1.2em;
  line-height: 100%;
  font-weight: 400;
  color: #424242;
}
#all-designs-xs p {
  font-size: 0.9em;
  color: #757575;
  font-weight: 300;
  margin-top: 20px;
}
#all-designs-xs img {
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
