<template>
  <v-card id="website-design" width="1000px" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE WEBSITE DESIGN</h1>
      </v-layout>
    </v-card-title>
    <v-card-text class="mt-12">
      <v-layout justify-space-between>
        <h2>ออกแบบ Website</h2>
        <p>Website เป็นหนึ่งในเครื่องมือในการสร้างพื้นที่ในการสื่อสารกับลูกค้า
          เป็นพื้นที่ที่เจ้าของธุรกิจและลูกค้าจะได้สื่อสารกันในเบื้องต้น ลูกค้าสามารถเรียนรู้และเข้าใจว่าคุณเป็นใคร ทำอะไร
          และมีสินค้าอะไรบ้าง ถือเป็นเครื่องมือที่เรียกความเชื่อมั่นจากลูกค้าได้ในอันดับแรก
          นอกจากนี้ยังสามารถใช้เป็นเครื่องในการทำ SEO เพื่อสร้างโอกาสการรับรู้แบรนด์กับลูกค้าได้อีกด้วย</p>
      </v-layout>
      <v-layout class="mt-12">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fwebsite%20design1.jpg?alt=media&token=d29650fe-386d-4f44-84e6-61a7e9e33821">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fwebsite%20design2.jpg?alt=media&token=e756062e-94d0-4820-aec9-8e23e44b7941">
        <img src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fwebsite%20design3.jpg?alt=media&token=45f6e0ea-9eb6-444e-93b9-f64bbf36f232">
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
}
</script>

<style>
#website-design {
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
}
#website-design h1 {
  font-size: 2.5em;
  line-height: 100%;
}
#website-design h2 {
  font-size: 1.5em;
  line-height: 100%;
  font-weight: 400;
}
#website-design p {
  width: 600px;
  font-size: 1.1em;
  color: #757575;
  font-weight: 300;
}
#website-design img {
  height: 200px;
  width: 33.33%;
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
