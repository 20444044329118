<template>
  <v-flex id="slide_bg_home2_xs">
    <v-layout style="width: 100%;" class="px-5" fill-height column align-center>
      <p>
        &emsp;&emsp;เพราะในปัจจุบันการตลาดและการประชาสัมพันธ์ถือเป็นปัจจัยหลักในการเติบโตของธุรกิจ เป็นส่วนหนึ่งในเครื่องมือที่ใช้
        สื่อสารทั้งภายในและภายนอกองค์กร เราจึงพร้อมสนับสนุนธุรกิจของคุณ โดยเฉพาะกลุ่มธุรกิจ Startup และ SME
        เพื่อลดต้นทุนในการผลิตเครื่องมือการตลาด การจ้างบุคลากรทางด้านนี้หรือ การใช้บริการบริษัทโฆษณาทั่วไปที่มีการบริการ
        ที่สูงและรับแค่กลุ่มธุรกิจใหญ่เท่านั้น 25 Second Creative เราเป็นหนึ่งในธุรกิจ Startup มาก่อน เราจึง
        เข้าใจถึงปัญหาเหล่านี้เราคุณและพร้อมสนับสนุนธุรกิจของคุณให้เติบโต
      </p>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  }
}
</script>

<style>
#slide_bg_home2_xs {
  width: 100%;
  position: relative;
  /* height: 100vh; */
  padding-bottom: 10%;
  background-color: #EC4D37;
}
#slide_bg_home2_xs h1 {
  font-size: 1.5em;
  font-weight: 500;
  color: white;
  line-height: 200%;
}
#slide_bg_home2_xs p {
  margin-bottom: 0;
  font-size: 0.8em;
  font-weight: 300;
  color: white;
}
</style>
