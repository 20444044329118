<template>
  <v-card id="video-production-xs" width="95%" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE VIDEO PRODUCTION</h1>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <h2>ออกแบบและผลิต VDO Content</h2>
      <p>&emsp; VDO Production เป็นหนึ่งในเครื่องมือที่ให้โฆษณาสินค้า เป็นการนำเสนอสินค้าในรูปแบบภาพเคลื่อนไหว
        เพื่อให้ลูกค้าเข้าใจสินค้าอย่างชัดเจนมากขึ้น สามารถกระตุ้นให้ลูกค้าเกิดความต้องการที่จะซื้อสินค้าหรือบริการมากขึ้น
        ในปัจจุบันการนำเสนอสินค้าในรูปแบบ VDO เป็นที่นิยมและได้ประสิทธิภาพมาก
        เพราะมีช่องทางที่เอื้อต่อการรับรู้ของลูกค้ามากมาย ไม่ว่าจะเป็น Facebook Youtube และ Instagram
        เป็นต้น จึงเป็นสิ่งที่ผู้ประกอบการไม่ควรมองข้าม</p>

      <carousel-3d :border="0" width="250" height="200">
        <slide :index="0">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FVDO%20production1.jpg?alt=media&token=fa0ae461-da22-43bb-9ea0-f78ea027c0dd">
        </slide>
        <slide :index="1">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FVDO%20production2.jpg?alt=media&token=d603f5e6-e271-4ec4-9c6d-508d7679f640">
        </slide>
        <slide :index="2">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FVDO%20production3.jpg?alt=media&token=29771638-84a2-4339-a36b-ddc037382e1f">
        </slide>
      </carousel-3d>
    </v-card-text>
  </v-card>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  components: {
    Carousel3d,
    Slide
  }
}
</script>

<style>
#video-production-xs {
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  overflow-y: auto;
  max-height: 100vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
#video-production-xs h1 {
  font-size: 1.3em;
  line-height: 100%;
  letter-spacing: -1px;
}
#video-production-xs h2 {
  font-size: 1.2em;
  line-height: 100%;
  font-weight: 400;
  color: #424242;
}
#video-production-xs p {
  font-size: 0.9em;
  color: #757575;
  font-weight: 300;
  margin-top: 20px;
}
#video-production-xs img {
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
