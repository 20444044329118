<template>
  <v-flex id="slide_bg_home3_xs">
    <v-layout justify-center column align-center fill-height>
      <p style="color: #EC4D37; font-size: 1.8em;">WHY US ?</p>
      <v-card width="95%" style="background: transparent;">
        <v-layout align-end justify-center>
          <v-flex>
            <v-flex class="flexcard">
            </v-flex>
            <v-card min-width="100px" class="round card-left">
              <v-layout class="column-height" column justify-center align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon2.svg">
                </v-flex>
                <p>ค่าใช้จ่าย</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon1.svg">
                </v-flex>
                <p>ความเชี่ยวชาญ</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon3.svg">
                </v-flex>
                <p>ประสบการณ์</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon4.svg">
                </v-flex>
                <p>คุณภาพงานที่ได้</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon5.svg">
                </v-flex>
                <p>ข้อจำกัดในการรับงาน</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon6.svg">
                </v-flex>
                <p>งานด่วน</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon7.svg">
                </v-flex>
                <p>ความเร็วในการทำงาน</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon8.svg">
                </v-flex>
                <p>ความยืนหยุ่นในการทำงาน</p>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex>
            <v-flex class="flexcard mb-2">
              <p class="text-center">รับพนักงาน<br>ประจำเฉพาะ</p>
            </v-flex>
            <v-card min-width="90px" class="round card-right" style="background-color: #546E7A; margin-left: 1px;">
              <v-layout class="column-height" column justify-center align-center>
                <p>ขึ้นอยู่กับ<br>ประสบการณ์</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>ขึ้นอยู่กับ<br>ประสบการณ์</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>ขึ้นอยู่กับ<br>ประสบการณ์</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>ขึ้นอยู่กับ<br>ประสบการณ์</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>น้อย</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สามารถทำได้</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex>
            <v-flex class="flexcard mb-2">
              <p class="text-center">บริษัทโฆษณาทั่วไป</p>
            </v-flex>
            <v-card min-width="70px" class="round card-right" style="background-color: #546E7A; margin-left: 1px;">
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>ตามตกลง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>น้อย</p>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex>
            <v-flex class="flexcard mb-2">
              <v-card class="py-1" style="background-color: #EC4D37; border-radius: 30px;">
                <P class="text-center pa-1 pb-0" style="font-size: 0.6em;">25 SECONDS <br>CREATIVE</P>
              </v-card>
            </v-flex>
            <v-card class="round card-right" style="background-color: #546E7A; margin-left: 1px;">
              <v-layout class="column-height" column justify-center align-center>
                <p>ปานกลาง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>น้อย</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สามารถทำได้</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" column justify-center align-center>
                <p>สูง</p>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  }
}
</script>

<style>
#slide_bg_home3_xs {
  width: 100%;
  /* height: 100vh; */
  padding-top: 5%;
  padding-bottom: 10%;
  position: relative;
  background-color: #000000;
}
#slide_bg_home3_xs p {
  margin-bottom: 5%;
  font-size: 0.6em;
  font-weight: 500;
  text-align: center;
}
#slide_bg_home3_xs img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#slide_bg_home3_xs .card-left p {
  color: #EC4D37;
}
#slide_bg_home3_xs .round {
  border-radius: 5px;
}
#slide_bg_home3_xs .card-left .theme--light.v-divider {
  /* border-color: #90A4AE !important; */
}
#slide_bg_home3_xs .column-height {
  height: 55px;
}
#slide_bg_home3_xs .card-right .theme--light.v-divider {
  border-color: #90A4AE !important;
}
#slide_bg_home3_xs .card-right p {
  color: white;
}
#slide_bg_home3_xs .flexcard p {
  color: white;
  font-size: 0.6em;
}
</style>
