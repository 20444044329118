<template>
  <v-flex id="slide_bg_home3">
    <p class="rotate text-center">WHY US ?</p>
    <v-layout justify-center align-center fill-height>
      <v-card width="800px" style="margin-left: 12%; background: transparent;">
        <v-layout align-end>
          <v-flex sm3>
            <v-flex class="flexcard">
            </v-flex>
            <v-card class="round card-left">
              <v-layout class="column-height" align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon2.svg">
                </v-flex>
                <p>ค่าใช้จ่าย</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon1.svg">
                </v-flex>
                <p>ความเชี่ยวชาญ</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon3.svg">
                </v-flex>
                <p>ประสบการณ์</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon4.svg">
                </v-flex>
                <p>คุณภาพงานที่ได้</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon5.svg">
                </v-flex>
                <p>ข้อจำกัดในการรับงาน</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon6.svg">
                </v-flex>
                <p>งานด่วน</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon7.svg">
                </v-flex>
                <p>ความเร็วในการทำงาน</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" align-center>
                <v-flex xs3>
                  <img width="20px" src="/icon/icon8.svg">
                </v-flex>
                <p>ความยืนหยุ่นในการทำงาน</p>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex sm3>
            <v-flex class="flexcard mb-2">
              <p class="text-center">รับพนักงานประจำเฉพาะ</p>
            </v-flex>
            <v-card class="round ml-2 card-right" style="background-color: #546E7A;">
              <v-layout class="column-height" justify-center align-center>
                <p>ขึ้นอยู่กับประสบการณ์</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>ขึ้นอยู่กับประสบการณ์</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>ขึ้นอยู่กับประสบการณ์</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>ขึ้นอยู่กับประสบการณ์</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>น้อย</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สามารถทำได้</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex sm3>
            <v-flex class="flexcard mb-2">
              <p class="text-center">บริษัทโฆษณาทั่วไป</p>
            </v-flex>
            <v-card class="round card-right" style="background-color: #546E7A; margin-left: 1px;">
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>ตามตกลง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>น้อย</p>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex sm3>
            <v-flex class="flexcard mb-2">
              <v-card class="py-1" style="background-color: #EC4D37; border-radius: 30px;">
                <P class="text-center" style="font-size: 1em;">25 SECONDS CREATIVE</P>
              </v-card>
            </v-flex>
            <v-card class="round card-right" style="background-color: #546E7A; margin-left: 1px;">
              <v-layout class="column-height" justify-center align-center>
                <p>ปานกลาง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>น้อย</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สามารถทำได้</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
              <v-divider></v-divider>
              <v-layout class="column-height" justify-center align-center>
                <p>สูง</p>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  }
}
</script>

<style>
#slide_bg_home3 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #000000;
}
#slide_bg_home3 .rotate {
  transform: rotate(-90deg) translate(-50%, -50%);
  position: absolute;
  transform-origin: top left;
  clear: both;
  float: left;
  top: 50%;
  left: 7%;
  font-weight: 500;
  width: 100vh;
  font-size: 2em;
  color: #EC4D37;
}
#slide_bg_home3 p {
  margin-bottom: 0em;
  font-size: 1em;
  font-weight: 400;
}
#slide_bg_home3 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#slide_bg_home3 .card-left p {
  color: #EC4D37;
}
#slide_bg_home3 .round {
  border-radius: 5px;
}
#slide_bg_home3 .theme--light.v-divider {
  border-color: #FFCCBC !important;
}
#slide_bg_home3 .column-height {
  height: 50px;
}
#slide_bg_home3 .card-right .theme--light.v-divider {
  border-color: #90A4AE !important;
}
#slide_bg_home3 .card-right p {
  color: white;
}
#slide_bg_home3 .flexcard p {
  color: white;
  font-size: 1.2em;
}
</style>
