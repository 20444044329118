<template>
  <v-card id="all-designs" width="1000px" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE ALL DESIGNS</h1>
      </v-layout>
    </v-card-title>
    <v-card-text class="mt-12">
      <v-layout justify-space-between>
        <h2>ออกแบบสื่อโฆษณาอื่นๆ</h2>
        <p>ยังมี Design อีกมายมายที่เป็นหนึ่งในเครื่องมือในวัถตุประสงค์ต่างๆ ที่ 25 Seconds Creative
          พร้อมจะสนับสนุนคุณ เพราะเราคือที่สุดแห่งการออกแบบในราคาที่จับต้องได้</p>
      </v-layout>
      <v-layout class="mt-12">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FAll%20Design1.jpg?alt=media&token=b219da4e-f065-48c5-874f-28a00e7da5c3">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FAll%20Design2.jpg?alt=media&token=bf05a04a-68d1-4580-890d-566f064a70c8">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FAll%20Design3.jpg?alt=media&token=a1a4e713-446e-4dfa-8ab6-d02efde2c4c7">
        <img src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FAll%20Design4.jpg?alt=media&token=7a4eb214-6ad0-4171-97fd-4706ea581eb1">
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
}
</script>

<style>
#all-designs {
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
}
#all-designs h1 {
  font-size: 2.5em;
  line-height: 100%;
}
#all-designs h2 {
  font-size: 1.5em;
  line-height: 100%;
  font-weight: 400;
}
#all-designs p {
  width: 600px;
  font-size: 1.1em;
  color: #757575;
  font-weight: 300;
}
#all-designs img {
  height: 200px;
  width: 25%;
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
