<template>
  <v-flex id="slide_bg_home5">
    <v-layout fill-height justify-center align-center>
      <v-card width="200px" style="background-color: black;">
        <p class="rotate text-center">WORKFOLIO</p>
      </v-card>
      <carousel
        :paginationEnabled="false"
        :perPageCustom="[[768, 2], [960, 2], [1264, 2.3]]"
        :scrollPerPage="false"
        :resistanceCoef="0"
        style="width: calc(100% - 200px)">
        <template v-for="(i, index) in items">
          <slide :key="index">
            <v-hover>
              <v-card slot-scope="{ hover }">
                <div style="position: relative; height: 100%; width: 100%">
                  <img height="100%" width="100%" style="object-fit: cover;"
                    :src="i"
                  >
                  <v-expand-transition>
                    <v-flex
                      v-if="hover"
                      class="v-card--reveal"
                      style="transition: height 0.3s;">
                      <v-layout justify-center align-center fill-height>
                        <v-btn large :to="{name: 'workfolio'}" color="#EC4D37" dark>see more</v-btn>
                      </v-layout>
                    </v-flex>
                  </v-expand-transition>
                </div>
              </v-card>
            </v-hover>
          </slide>
        </template>
      </carousel>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  data () {
    return {
      items: [
        'https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/WORKFOLIO%2F%E0%B8%A0%E0%B8%B2%E0%B8%9E-1.jpg?alt=media&token=9e9d3547-aa75-466f-a3ea-609fbb42e3e1',
        'https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/WORKFOLIO%2F%E0%B8%A0%E0%B8%B2%E0%B8%9E-2.jpg?alt=media&token=7ef46134-e8b1-4e9a-999c-2da9b2175ef4',
        'https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/WORKFOLIO%2F%E0%B8%A0%E0%B8%B2%E0%B8%9E3.jpg?alt=media&token=51789e36-ca60-4fd8-b245-25a149d422dc',
        'https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/WORKFOLIO%2F%E0%B8%A0%E0%B8%B2%E0%B8%9E4.jpg?alt=media&token=c543cb9d-829a-4dcf-a8ec-0b8715548f21',
        'https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/WORKFOLIO%2F%E0%B8%A0%E0%B8%B2%E0%B8%9E5.jpg?alt=media&token=7b639e9a-61a6-4ad2-9818-400f0a80fe9d',
        'https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/WORKFOLIO%2F%E0%B8%A0%E0%B8%B2%E0%B8%9E6.jpg?alt=media&token=7cf863dd-1605-4e88-9a19-2b524cad9345'
      ]
    }
  },
  components: {
  },
  computed: {
    workfolio () {
      return this.$store.getters['getWorkfolio']
    }
  }
}
</script>

<style>
#slide_bg_home5 {
  width: 100%;
  position: relative;
  height: 100vh;
  background-color: black;
}
#slide_bg_home5 .rotate {
  transform: rotate(-90deg) translate(-50%, -50%);
  position: absolute;
  transform-origin: top left;
  clear: both;
  float: left;
  width: 100vh;
  top: 50%;
  left: 20%;
  font-size: 2em;
  font-weight: 500;
  color: white;
}
#slide_bg_home5 .v-card {
  height: 100vh;
  position: relative;
}
#slide_bg_home5 .card-title {
  font-size: 1.5em;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 15px;
  color: #757575;
}
#slide_bg_home5 .v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(92, 92, 92, .5);
}
</style>
