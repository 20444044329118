<template>
  <v-flex id="slide_bg_home4">
    <v-layout justify-center align-center fill-height>
      <v-card width="200px" style="background-color: #EC4D37;">
        <p class="rotate text-center">OUR SERVICES</p>
      </v-card>
      <carousel
        :paginationEnabled="false"
        :perPageCustom="[[768, 2.5], [960, 2.2], [1264, 4.2]]"
        scrollPerPage
        :resistanceCoef="0"
        style="width: calc(100% - 200px)">
        <slide>
          <v-hover>
            <v-card
              slot-scope="{ hover }"
            >
              <img width="100%" :height="hover ? '20%' : '70%'" style="object-fit: cover; transition: height 0.3s"
                src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/services%2F1.brand%20identity.jpg?alt=media&token=b5ff8395-5aa1-4c1d-948e-64b423dc08f5"
              >
              <v-card-text class="py-3 pr-4">
                <p class="card-number text-right">1</p>
                <p class="card-title">Brand<br>Identity</p>
                <p class="card-text">ออกแบบและสร้างแบรนด์</p>
                <span class="icon" :style="hover? 'background-color: #EC4D37' : 'background-color: #FFEBEE'"></span>

                <transition-group name="list">
                  <p :key="1" class="card-text-hover" v-if="hover">- ออกแบบ Logo</p>
                  <p :key="2" class="card-text-hover" v-if="hover">- ออกแบบ Packaging</p>
                  <p :key="3" class="card-text-hover" v-if="hover">- ออกแบบ Name card</p>
                  <p :key="4" class="card-text-hover" v-if="hover">- ออกแบบ Corporate Identity (CI)</p>
                  <p :key="5" class="card-text-hover" v-if="hover">- ออกแบบฉลาก ตราสินค้า</p>
                  <p :key="6" class="card-text-hover" v-if="hover">- อื่นๆ</p>
                  <v-layout :key="7" justify-center>
                    <v-btn :to="{ name: 'brand-identity' }" color="#EC4D37" class="mt-6 py-1 px-7" outlined v-if="hover">more details</v-btn>
                  </v-layout>
                </transition-group>
              </v-card-text>
            </v-card>
          </v-hover>
        </slide>
        <slide>
          <v-hover>
            <v-card
              slot-scope="{ hover }"
            >
              <img width="100%" :height="hover ? '20%' : '70%'" style="object-fit: cover; transition: height 0.3s"
                src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/services%2F2.website%20design.jpg?alt=media&token=941e0de7-54fe-42b6-8dd4-5716c99f20f6"
              >
              <v-card-text class="py-3 pr-4">
                <p class="card-number text-right">2</p>
                <p class="card-title">Website<br>Design</p>
                <p class="card-text">ออกแบบ Website</p>
                <span class="icon" :style="hover? 'background-color: #EC4D37' : 'background-color: #FFEBEE'"></span>

                <transition-group name="list">
                  <p :key="1" class="card-text-hover" v-if="hover">- UI/UX Design</p>
                  <p :key="2" class="card-text-hover" v-if="hover">- Landing Page</p>
                  <v-layout :key="3" justify-center>
                    <v-btn :to="{ name: 'website-design' }" color="#EC4D37" class="mt-6 py-1 px-7" outlined v-if="hover">more details</v-btn>
                  </v-layout>
                </transition-group>
              </v-card-text>
            </v-card>
          </v-hover>
        </slide>
        <slide>
          <v-hover>
            <v-card
              slot-scope="{ hover }"
            >
              <img width="100%" :height="hover ? '20%' : '70%'" style="object-fit: cover; transition: height 0.3s"
                src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/services%2F3.App%20design.jpg?alt=media&token=9f5f16c4-36ce-4a59-ac58-4355e35abf65"
              >
              <v-card-text class="py-3 pr-4">
                <p class="card-number text-right">3</p>
                <p class="card-title">Application<br>Design</p>
                <p class="card-text">ออกแบบ Application</p>
                <span class="icon" :style="hover? 'background-color: #EC4D37' : 'background-color: #FFEBEE'"></span>

                <transition-group name="list">
                  <p :key="1" class="card-text-hover" v-if="hover">- UI/UX Design</p>
                  <v-layout :key="2" justify-center>
                    <v-btn :to="{ name: 'application-design' }" color="#EC4D37" class="mt-6 py-1 px-7" outlined v-if="hover">more details</v-btn>
                  </v-layout>
                </transition-group>
              </v-card-text>
            </v-card>
          </v-hover>
        </slide>
        <slide>
          <v-hover>
            <v-card
              slot-scope="{ hover }"
            >
              <img width="100%" :height="hover ? '20%' : '70%'" style="object-fit: cover; transition: height 0.3s"
                src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/services%2F4.content%20editor.jpg?alt=media&token=51e0788e-5c5f-4b09-bd2d-94cfdc8517b9"
              >
              <v-card-text class="py-3 pr-4">
                <p class="card-number text-right">4</p>
                <p class="card-title">Content<br>Editor</p>
                <p class="card-text">วางแผนและเขียน Content</p>
                <span class="icon" :style="hover? 'background-color: #EC4D37' : 'background-color: #FFEBEE'"></span>

                <transition-group name="list">
                  <p :key="1" class="card-text-hover" v-if="hover">- Facebook Content</p>
                  <p :key="2" class="card-text-hover" v-if="hover">- Website Content</p>
                  <v-layout :key="3" justify-center>
                    <v-btn :to="{ name: 'content-editor' }" color="#EC4D37" class="mt-6 py-1 px-7" outlined v-if="hover">more details</v-btn>
                  </v-layout>
                </transition-group>
              </v-card-text>
            </v-card>
          </v-hover>
        </slide>
        <slide>
          <v-hover>
            <v-card
              slot-scope="{ hover }"
            >
              <img width="100%" :height="hover ? '20%' : '70%'" style="object-fit: cover; transition: height 0.3s"
                src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/services%2F5.graphic%20design.jpg?alt=media&token=accaf880-c7cf-402c-8e6d-88619204f59d"
              >
              <v-card-text class="py-3 pr-4">
                <p class="card-number text-right">5</p>
                <p class="card-title">Graphic<br>Design</p>
                <p class="card-text">ออกแบบภาพที่ใช้ในการโฆษณา</p>
                <span class="icon" :style="hover? 'background-color: #EC4D37' : 'background-color: #FFEBEE'"></span>

                <transition-group name="list">
                  <p :key="1" class="card-text-hover" v-if="hover">- ออกแบบรูปประกอบ Content</p>
                  <p :key="2" class="card-text-hover" v-if="hover">- ออกแบบ Print ads</p>
                  <p :key="3" class="card-text-hover" v-if="hover">- ออกแบบ Cover page</p>
                  <p :key="4" class="card-text-hover" v-if="hover">- ออกแบบ Banner</p>
                  <p :key="5" class="card-text-hover" v-if="hover">- ออกแบบ Leaflet {{'\&'}} Brochure</p>
                  <p :key="6" class="card-text-hover" v-if="hover">- ออกแบบ Member card, Gift Voucher</p>
                  <p :key="7" class="card-text-hover" v-if="hover">- ออกแบบ GIF</p>
                  <p :key="8" class="card-text-hover" v-if="hover">- ออกแบบ Ads</p>
                  <p :key="9" class="card-text-hover" v-if="hover">- ออกแบบ Infographic</p>
                  <p :key="10" class="card-text-hover" v-if="hover">- ออกแบบ Motion Graphic</p>
                  <p :key="11" class="card-text-hover" v-if="hover">- ออกแบบภาพ 3 มิติ Perspective</p>
                  <v-layout :key="12" justify-center>
                    <v-btn :to="{ name: 'graphic-design' }" color="#EC4D37" class="mt-6 py-1 px-7" outlined v-if="hover">more details</v-btn>
                  </v-layout>
                </transition-group>
              </v-card-text>
            </v-card>
          </v-hover>
        </slide>
        <slide>
          <v-hover>
            <v-card
              slot-scope="{ hover }"
            >
              <img width="100%" :height="hover ? '20%' : '70%'" style="object-fit: cover; transition: height 0.3s"
                src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/services%2F6.product%20photography.jpg?alt=media&token=f6e422e2-cf75-4bcc-984d-961680750ff2"
              >
              <v-card-text class="py-3 pr-4">
                <p class="card-number text-right">6</p>
                <p class="card-title">Product<br>Photography</p>
                <p class="card-text">บริการรับถ่ายภาพสินค้า</p>
                <span class="icon" :style="hover? 'background-color: #EC4D37' : 'background-color: #FFEBEE'"></span>

                <transition-group name="list">
                  <p :key="1" class="card-text-hover" v-if="hover">- Packshot</p>
                  <p :key="2" class="card-text-hover" v-if="hover">- Photo Stock</p>
                  <v-layout :key="3" justify-center>
                    <v-btn :to="{ name: 'product-photography' }" color="#EC4D37" class="mt-6 py-1 px-7" outlined v-if="hover">more details</v-btn>
                  </v-layout>
                </transition-group>
              </v-card-text>
            </v-card>
          </v-hover>
        </slide>
        <slide>
          <v-hover>
            <v-card
              slot-scope="{ hover }"
            >
              <img width="100%" :height="hover ? '20%' : '70%'" style="object-fit: cover; transition: height 0.3s"
                src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/services%2F7.VDO%20production.jpg?alt=media&token=428e70c1-7351-4f4b-b79f-dbe5e5b4ba78"
              >
              <v-card-text class="py-3 pr-4">
                <p class="card-number text-right">7</p>
                <p class="card-title">Video<br>Production</p>
                <p class="card-text">ออกแบบและผลิต VDO Content</p>
                <span class="icon" :style="hover? 'background-color: #EC4D37' : 'background-color: #FFEBEE'"></span>

                <transition-group name="list">
                  <p :key="1" class="card-text-hover" v-if="hover">- ตัดต่อ VDO ทั่วไป</p>
                  <p :key="2" class="card-text-hover" v-if="hover">- ออกแบบและผลิต VDO</p>
                  <v-layout :key="3" justify-center>
                    <v-btn :to="{ name: 'video-production' }" color="#EC4D37" class="mt-6 py-1 px-7" outlined v-if="hover">more details</v-btn>
                  </v-layout>
                </transition-group>
              </v-card-text>
            </v-card>
          </v-hover>
        </slide>
        <slide>
          <v-hover>
            <v-card
              slot-scope="{ hover }"
            >
              <img width="100%" :height="hover ? '20%' : '70%'" style="object-fit: cover; transition: height 0.3s"
                src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/services%2F8.all%20design.jpg?alt=media&token=20c3643a-3961-4c6d-b6cc-e1f475052a03"
              >
              <v-card-text class="py-3 pr-4">
                <p class="card-number text-right">8</p>
                <p class="card-title">All<br>Designs</p>
                <p class="card-text">ออกแบบสื่อโฆษณาอื่นๆ</p>
                <span class="icon" :style="hover? 'background-color: #EC4D37' : 'background-color: #FFEBEE'"></span>

                <transition-group name="list">
                  <p :key="1" class="card-text-hover" v-if="hover">- ออกแบบ Roll up</p>
                  <p :key="2" class="card-text-hover" v-if="hover">- ออกแบบ X Stand</p>
                  <p :key="3" class="card-text-hover" v-if="hover">- ออกแบบ Standee</p>
                  <p :key="4" class="card-text-hover" v-if="hover">- ออกแบบ J-flag</p>
                  <p :key="5" class="card-text-hover" v-if="hover">- ออกแบบ Backdrop</p>
                  <p :key="6" class="card-text-hover" v-if="hover">- งานออกแบบอื่นๆ</p>
                  <v-layout :key="7" justify-center>
                    <v-btn :to="{ name: 'all-designs' }" color="#EC4D37" class="mt-6 py-1 px-7" outlined v-if="hover">more details</v-btn>
                  </v-layout>
                </transition-group>
              </v-card-text>
            </v-card>
          </v-hover>
        </slide>
      </carousel>
    </v-layout>
  </v-flex>
</template>

<script>

export default {
  data () {
    return {
    }
  },
  components: {
  }
}
</script>

<style>
#slide_bg_home4 {
  width: 100%;
  position: relative;
  height: 100vh;
  background-color: black;
}
#slide_bg_home4 .rotate {
  transform: rotate(-90deg) translate(-50%, -50%);
  position: absolute;
  transform-origin: top left;
  clear: both;
  float: left;
  width: 100vh;
  top: 50%;
  font-weight: 500;
  left: 20%;
  font-size: 2em;
  font-weight: 500;
  color: white;
}
#slide_bg_home4 p.card-number {
  margin-bottom: 0em;
  font-size: 70px;
  line-height: 90%;
  color: #EC4D37;
}
#slide_bg_home4 p.card-title {
  margin-bottom: 0em;
  line-height: 100%;
  font-size: 30px;
  margin-top: -5%;
  font-weight: 500;
}
#slide_bg_home4 p.card-text {
  margin-bottom: 0em;
  line-height: 100%;
  font-size: 1em;
  margin-top: 4%;
  font-weight: 500;
  color: #A1A1A1;
}
#slide_bg_home4 p.card-text-hover {
  margin-bottom: 0em;
  line-height: 100%;
  font-size: 1em;
  margin-top: 4%;
  font-weight: 300;
  color: #A1A1A1;
}
#slide_bg_home4 .v-card {
  height: 100vh;
  position: relative;
}
#slide_bg_home4 .icon {
  height: 5px;
  width: 40px;
  margin-top: 5%;
  background-color: #FFEBEE;
  border-radius: 50px;
  display: inline-block;
}
.list-enter-active, .list-leave-active {
  transition: all 0.7s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
