<template>
  <v-flex id="slide_bg_home6_xs">
    <v-card flat>
      <h2>Have a project in mind,<br><span style="font-weight: 400;">Don’t Hesitate.</span></h2>
      <v-btn :to="{ name: 'contact-us' }" outlined color="white" class="ml-0 mt-5 px-7 pt-1">contact us</v-btn>
    </v-card>
    <!-- <p class="caption copyright">&copy; 2019. 25 Seconds Creative Agency Bangkok, Thailand.</p> -->
    <p class="contact">Start a Conversation<br><span style="font-size: 1.2em; text-decoration: underline;">aksika.a@25secondscreative.com</span></p>
  </v-flex>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  }
}
</script>

<style>
#slide_bg_home6_xs {
  width: 100%;
  position: relative;
  height: 100vh;
  background-color: #EC4D37;
}
#slide_bg_home6_xs p {
  margin-bottom: 0.5em;
  font-size: 1.25em;
  font-weight: 300;
  color: white;
}
#slide_bg_home6_xs .v-card {
  position: absolute;
  top: 50%;
  left: 10%;
  font-weight: 300;
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  background-color: transparent;
}
#slide_bg_home6_xs h2 {
  color: white;
  font-weight: 300;
  line-height: 1.5em;
  font-size: 1.5em;
}
#slide_bg_home6_xs .copyright {
  margin-bottom: 0px;
  position: absolute;
  bottom: 5%;
  left: 8%;
}
#slide_bg_home6_xs .contact {
  margin-bottom: 0px;
  position: absolute;
  bottom: 5%;
  left: 8%;
  font-size: 0.8em;
}
</style>
