import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    workfolio: []
  },
  getters: {
    getWorkfolio: (state) => {
      return state.workfolio
    }
  },
  mutations: {
    setWorkfolio (state, workfolio) {
      state.workfolio = workfolio
    }
  },
  actions: {
    async fetchWorkfolio ({ commit }) {
      const db = firebase.firestore()
      let data = await db.collection('workfolio').get()
      let list = []
      for (let i of data.docs) {
        let temp = i.data()
        temp['id'] = i.id
        list.push(temp)
      }
      commit('setWorkfolio', list)
    }
  }
})
