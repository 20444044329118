<template>
  <v-layout id="workfolio-xs" fill-height justify-center>
    <v-flex sm11>
      <v-card :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-top: 15%;'" color="transparent" flat>
        <v-card-title>
          <v-layout wrap column>
            <h1 style="line-height: 100%; font-size: 2em;" class="mb-5" :style="$vuetify.breakpoint.mdAndUp ? '' : 'font-size: 1.25em;'">WORKFOLIO</h1>

            <p class="mb-2 p-title">รับทำ Creative Design สื่อโฆษณาและสื่อสิ่งพิมพ์ทุกชนิด ทั้งภาษาไทยและภาษาอังกฤษ</p>
            <p class="mb-2 p-title">
            -บริการออกแบบและสร้างแบรนด์<br>
            -บริการออกแบบ Website และ Application<br>
            -บริการวางแผนและเขียน Content<br>
            -บริการทำ Graphic Design ทุกรูปแบบ<br>
            -บริการถ่ายภาพสินค้า<br>
            -บริการตัดต่อและผลิต VDO</p>
            <p class="mb-0 p-title">
            เพราะการออกแบบจะช่วย...สร้างความเป็นตัวคุณ สร้างความน่าเชื่อถือให้กับแบรนด์ สร้างมูลค่าให้กับสินค้า และสร้างภาพจดจำให้กับลูกค้าของคุณ</p>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-row wrap class="mt-2">
            <v-col v-for="(i, index) in items" :key="index" cols="6" class="pa-1">
              <v-hover v-slot:default="{ hover }">
                <img @click="selectImage(i)" :class="hover ? 'elevation-5' : 'elevation-1'" width="100%" style="display: block" :src="i.src" :alt="i.name">
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
        <v-dialog
          v-model="dialog"
          width="650px"
        >
          <div style="position: relative;">
            <img width="100%" style="display: block;" :src="selectData.src" :alt="selectData.name">
            <v-btn @click="dialog = false" style="position: absolute; top: 0; right: 0;" large icon><v-icon>mdi-close-circle</v-icon></v-btn>
          </div>
        </v-dialog>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      selectData: ''
    }
  },
  mounted () {
  },
  computed: {
    workfolio () {
      return this.$store.getters['getWorkfolio']
    },
    items () {
      let workfolio = JSON.parse(JSON.stringify(this.workfolio))
      let sortdata = workfolio.sort(function (a, b) {
        return a.index - b.index
      })
      return sortdata
    }
  },
  watch: {
  },
  methods: {
    selectImage (data) {
      this.selectData = data
      this.dialog = true
    }
  },
  components: {
  }
}
</script>

<style>
#workfolio-xs .p-title {
  font-size: 0.65em;
  color: #757575;
  line-height: 1.5em;
}
#workfolio-xs {
  overflow-y: auto;
  max-height: 100vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
</style>
