<template>
  <v-card id="content-editor" width="1000px" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE CONTENT EDITOR</h1>
      </v-layout>
    </v-card-title>
    <v-card-text class="mt-12">
      <v-layout justify-space-between>
        <h2>วางแผนและเขียน Content</h2>
        <p>Content เป็นหนึ่งในเครื่องมือในการสร้างการรับรู้และสร้างความสัมพันธ์ที่ดีกับลูกค้า แบรนด์สามารถใช้
          Content ในการสื่อสารกับลูกค้าได้ ไม่เพียงแค่การขายของ แต่เป็นการให้สาระความรู้ที่เป็นประโยชน์กับลูกค้า
          เพิ่มคุณค่าให้กับแบรนด์ของคุณ สร้างความน่าเชื่อถือให้กับธุรกิจของคุณได้ และหาก Content
          นั้นโดนใจลูกค้าก็จะทำให้เพิ่มโอกาสการรับรู้แบรนด์ต่อไปได้</p>
      </v-layout>
      <v-layout class="mt-12">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fcontent%20editor1.jpg?alt=media&token=e5c7a3e2-b126-4570-894d-f7eb419706bf">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fcontent%20editor2.jpg?alt=media&token=90a4e674-d536-4b2a-80b1-29e579bbcddc">
        <img src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fcontent%20editor3.jpg?alt=media&token=1a2e8872-2009-4bf1-bbcc-31f7b9b495aa">
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
}
</script>

<style>
#content-editor {
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
}
#content-editor h1 {
  font-size: 2.5em;
  line-height: 100%;
}
#content-editor h2 {
  font-size: 1.5em;
  line-height: 100%;
  font-weight: 400;
}
#content-editor p {
  width: 600px;
  font-size: 1.1em;
  color: #757575;
  font-weight: 300;
}
#content-editor img {
  height: 200px;
  width: 33.33%;
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
