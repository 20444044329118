<template>
  <v-layout id="our-services-xs" fill-height justify-center>
    <v-flex sm11>
      <v-card :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-top: 15%;'" color="transparent" flat>
        <v-card-title>
          <v-layout wrap justify-space-between align-start>
            <h1 style="line-height: 100%; font-size: 2em;" class="mb-10" :style="$vuetify.breakpoint.mdAndUp ? '' : 'font-size: 1.25em;'">OUR SERVICES</h1>
            <p class="mb-0 p-title">&emsp; ในปัจจุบันการตลาดและการประชาสัมพันธ์ถือเป็น
            ปัจจัยหลักในการเติบโตของธุรกิจ เป็นส่วนหนึ่งใน
            เครื่องมือที่ใช้สื่อสารทั้งภายในและภายนอกองค์กร
            เราจึงพร้อมสนับสนุนธุรกิจของคุณ โดยเฉพาะกลุ่ม
            ธุรกิจ Startup และ SME เพื่อลดต้นทุนในการ
            ผลิตเครื่องมือการตลาด การจ้างบุคลากรทางด้านนี้
            หรือ การใช้บริการบริษัทโฆษณาทั่วไปที่มีการบริการที่
            สูงและรับแค่กลุ่มธุรกิจใหญ่เท่านั้น 25 Second
            Creative เราเป็นหนึ่งในธุรกิจ Startup มา
            ก่อน เราจึงเข้าใจถึงปัญหาเหล่านี้เราคุณและพร้อม
            สนับสนุนธุรกิจของคุณให้เติบโต</p>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-layout wrap class="card-services mt-5" justify-center>
            <v-layout justify-center>
              <v-hover>
                <v-card slot-scope="{ hover }">
                  <v-layout fill-height column justify-center align-center>
                    <img v-show="hover" height="50px" src="/icon/idea.svg">
                    <img v-show="!hover" height="50px" src="/icon/orange-idea.svg">
                    <h2>BRAND IDENTITY</h2>
                    <p>ออกแบบและสร้างแบรนด์</p>
                    <v-btn v-show="hover" :to="{ name: 'brand-identity' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
                  </v-layout>
                </v-card>
              </v-hover>
              <v-hover>
                <v-card slot-scope="{ hover }" class="ml-1">
                  <v-layout fill-height column justify-center align-center>
                    <img v-show="hover" height="50px" src="/icon/white-web.svg">
                    <img v-show="!hover" height="50px" src="/icon/website.svg">
                    <h2>WEBSITE DESIGN</h2>
                    <p>ออกแบบ Website</p>
                    <v-btn v-show="hover" :to="{ name: 'website-design' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
                  </v-layout>
                </v-card>
              </v-hover>
            </v-layout>
            <v-layout class="mt-1" justify-center>
              <v-hover>
                <v-card slot-scope="{ hover }">
                  <v-layout fill-height column justify-center align-center>
                    <img v-show="hover" height="50px" src="/icon/white-app.svg">
                    <img v-show="!hover" height="50px" src="/icon/mobile.svg">
                    <h2>APPLICATION DESIGN</h2>
                    <p>ออกแบบ Application</p>
                    <v-btn v-show="hover" :to="{ name: 'application-design' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
                  </v-layout>
                </v-card>
              </v-hover>
              <v-hover>
                <v-card slot-scope="{ hover }" class="ml-1">
                  <v-layout fill-height column justify-center align-center>
                    <img v-show="hover" height="50px" src="/icon/white-content.svg">
                    <img v-show="!hover" height="50px" src="/icon/design.svg">
                    <h2>CONTENT EDITOR</h2>
                    <p>วางแผนและเขียน Content</p>
                    <v-btn v-show="hover" :to="{ name: 'content-editor' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
                  </v-layout>
                </v-card>
              </v-hover>
            </v-layout>
          </v-layout>
          <v-layout wrap class="card-services" justify-center>
            <v-layout class="mt-1" justify-center>
              <v-hover>
                <v-card slot-scope="{ hover }">
                  <v-layout fill-height column justify-center align-center>
                    <img v-show="hover" height="50px" src="/icon/white-graphic.svg">
                    <img v-show="!hover" height="50px" src="/icon/computer.svg">
                    <h2>GRAPHIC DESIGN</h2>
                    <p>ออกแบบภาพที่ใช้ใน<br>การโฆษณา</p>
                    <v-btn v-show="hover" :to="{ name: 'graphic-design' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
                  </v-layout>
                </v-card>
              </v-hover>
              <v-hover>
                <v-card slot-scope="{ hover }" class="ml-1">
                  <v-layout fill-height column justify-center align-center>
                    <img v-show="hover" height="50px" src="/icon/white-photo.svg">
                    <img v-show="!hover" height="50px" src="/icon/polaroid-pictures.svg">
                    <h2>PRODUCT<br>PHOTOGRAPHY</h2>
                    <p>บริการรับถ่ายภาพสินค้า</p>
                    <v-btn v-show="hover" :to="{ name: 'product-photography' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
                  </v-layout>
                </v-card>
              </v-hover>
            </v-layout>
            <v-layout class="mt-1" justify-center>
              <v-hover>
                <v-card slot-scope="{ hover }">
                  <v-layout fill-height column justify-center align-center>
                    <img v-show="hover" height="50px" src="/icon/white-vdo.svg">
                    <img v-show="!hover" height="50px" src="/icon/video-camera.svg">
                    <h2>VIDEO PRODUCTION</h2>
                    <p>ออกแบบและผลิต VDO<br>Content</p>
                    <v-btn v-show="hover" :to="{ name: 'video-production' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
                  </v-layout>
                </v-card>
              </v-hover>
              <v-hover>
                <v-card slot-scope="{ hover }" class="ml-1">
                  <v-layout fill-height column justify-center align-center>
                    <img v-show="hover" height="50px" src="/icon/white-alldesign.svg">
                    <img v-show="!hover" height="50px" src="/icon/think.svg">
                    <h2>ALL DESIGNS</h2>
                    <p>ออกแบบสื่อโฆษณาอื่นๆ</p>
                    <v-btn v-show="hover" :to="{ name: 'all-designs' }" outlined color="white" small class="px-5 mt-1">more details</v-btn>
                  </v-layout>
                </v-card>
              </v-hover>
            </v-layout>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  components: {
  }
}
</script>

<style>
#our-services-xs .p-title {
  font-size: 0.65em;
  color: #757575;
  line-height: 1.5em;
}
#our-services-xs {
  overflow-y: auto;
  max-height: 100vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
#our-services-xs .card-services .v-card {
  width: 237px;
  height: 170px;
  border-radius: 7px;
  background-color: white;
}
#our-services-xs .card-services .v-card:hover {
  width: 237px;
  height: 170px;
  border-radius: 7px;
  background-color: #EC4D37;
}
#our-services-xs .card-services .v-card h2 {
  font-size: 1.3em;
  line-height: 100%;
  margin-top: 10px;
  margin-bottom: 3px;
  font-weight: 500;
  color: #EC4D37;
}
#our-services-xs .card-services .v-card:hover h2 {
  color: white;
}
#our-services-xs .card-services .v-card p {
  font-weight: 300;
  color: #EC4D37;
  margin-bottom: 0;
}
#our-services-xs .card-services .v-card:hover p {
  color: white;
}
</style>
