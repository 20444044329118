<template>
  <v-card id="application-design" width="1000px" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE APPLICATION DESIGN</h1>
      </v-layout>
    </v-card-title>
    <v-card-text class="mt-12">
      <v-layout justify-space-between>
        <h2>ออกแบบ Application</h2>
        <p>Appliacation เป็นหนึ่งในเครื่องมือในการสร้างรายได้อีกช่องทาง เป็น platform
          ในการสร้างพื้นที่ซื้อขายสินค้า รีวิวสินค้าและค้นหาสินค้าได้ เป็นการส่งเสริมภาพลักษณ์ให้กับแบรนด์
          ทำให้ลูกค้าเกิดความสะดวกและรวดเร็วมากขึ้นในการซื้อสินค้าและใช้บริการ
          ถือเป็นพื้นที่ในการสร้างความประทับกับลูกค้าได้</p>
      </v-layout>
      <v-layout class="mt-12">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FApp%20design1.jpg?alt=media&token=29753413-6b52-44d4-92a6-050be11e293a">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FApp%20design2.jpg?alt=media&token=f1b07d6d-90a7-4e12-930c-ea5e97bde6cf">
        <img src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FApp%20design3.jpg?alt=media&token=7c562b14-f108-403f-9bf2-bf91e32a68fb">
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
}
</script>

<style>
#application-design {
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
}
#application-design h1 {
  font-size: 2.5em;
  line-height: 100%;
}
#application-design h2 {
  font-size: 1.5em;
  line-height: 100%;
  font-weight: 400;
}
#application-design p {
  width: 600px;
  font-size: 1.1em;
  color: #757575;
  font-weight: 300;
}
#application-design img {
  height: 200px;
  width: 33.33%;
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
