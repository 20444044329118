<template>
  <v-card id="application-design-xs" width="95%" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE APPLICATION DESIGN</h1>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <h2>ออกแบบ Application</h2>
      <p>&emsp; Appliacation เป็นหนึ่งในเครื่องมือในการสร้างรายได้อีกช่องทาง เป็น platform
        ในการสร้างพื้นที่ซื้อขายสินค้า รีวิวสินค้าและค้นหาสินค้าได้ เป็นการส่งเสริมภาพลักษณ์ให้กับแบรนด์
        ทำให้ลูกค้าเกิดความสะดวกและรวดเร็วมากขึ้นในการซื้อสินค้าและใช้บริการ
        ถือเป็นพื้นที่ในการสร้างความประทับกับลูกค้าได้</p>

      <carousel-3d :border="0" width="250" height="200">
        <slide :index="0">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FApp%20design1.jpg?alt=media&token=29753413-6b52-44d4-92a6-050be11e293a">
        </slide>
        <slide :index="1">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FApp%20design2.jpg?alt=media&token=f1b07d6d-90a7-4e12-930c-ea5e97bde6cf">
        </slide>
        <slide :index="2">
          <img width="100%" height="100%" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2FApp%20design3.jpg?alt=media&token=7c562b14-f108-403f-9bf2-bf91e32a68fb">
        </slide>
      </carousel-3d>
    </v-card-text>
  </v-card>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  components: {
    Carousel3d,
    Slide
  }
}
</script>

<style>
#application-design-xs {
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  overflow-y: auto;
  max-height: 100vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
#application-design-xs h1 {
  font-size: 1.3em;
  line-height: 100%;
  letter-spacing: -2px;
}
#application-design-xs h2 {
  font-size: 1.2em;
  line-height: 100%;
  font-weight: 400;
  color: #424242;
}
#application-design-xs p {
  font-size: 0.9em;
  color: #757575;
  font-weight: 300;
  margin-top: 20px;
}
#application-design-xs img {
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
