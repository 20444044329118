<template>
  <v-card id="product-photography" width="1000px" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE PRODUCT PHOTOGRAPHY</h1>
      </v-layout>
    </v-card-title>
    <v-card-text class="mt-12">
      <v-layout justify-space-between>
        <h2>บริการรับถ่ายภาพสินค้า</h2>
        <p>Product Photography เป็นหนึ่งในเครื่องมือที่ให้โฆษณาสินค้า สามารถนำไปใช้ได้ในทุกแบบ
          ทั้งทำเป็นสื่อโฆษณา เป็นภาพเป็นประกอบใน website Application เป็นต้น เป็นภาพประกอบในการทำ
          Content ทำเป็นรูป Profile หรือ รูป Cover และอื่นๆ
          เรียกได้ว่าถ่ายภาพเพียงครั้งเดียวสามารถนำมาใช้ได้ในระยะยาว</p>
      </v-layout>
      <v-layout class="mt-12">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fproduct%20photography1.jpg?alt=media&token=a91a0546-8dd8-4c00-aeff-a5b98f2b8c00">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fproduct%20photography2.jpg?alt=media&token=ca093f49-bd8f-4e8a-bf7b-f3781970b93c">
        <img src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fproduct%20photography3.jpg?alt=media&token=9dfba236-fb1b-45c3-bc01-75aac4d67977">
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
}
</script>

<style>
#product-photography {
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
}
#product-photography h1 {
  font-size: 2.5em;
  line-height: 100%;
}
#product-photography h2 {
  font-size: 1.5em;
  line-height: 100%;
  font-weight: 400;
}
#product-photography p {
  width: 600px;
  font-size: 1.1em;
  color: #757575;
  font-weight: 300;
}
#product-photography img {
  height: 200px;
  width: 33.33%;
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
