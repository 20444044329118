<template>
  <web v-if="$vuetify.breakpoint.mdAndUp"/>
  <mobile v-else/>
</template>

<script>
import web from '@/components/BrandIdentity/web'
import mobile from '@/components/BrandIdentity/mobile'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  components: {
    web,
    mobile
  }
}
</script>

<style>
</style>
