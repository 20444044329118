<template>
  <v-card id="graphic-design" width="1000px" color="transparent" flat>
    <v-card-title>
      <v-layout justify-space-between align-start>
        <h1>CREATE GRAPHIC DESIGN</h1>
      </v-layout>
    </v-card-title>
    <v-card-text class="mt-12">
      <v-layout justify-space-between>
        <h2>ออกแบบกราฟฟิกดีไซน์ที่ใช้ในการโฆษณา</h2>
        <p>Graphic Design เป็นหนึ่งในเครื่องมือในการโฆษณาสินค้า
          เพื่อกระตุ้นให้ลูกค้าเกิดความต้องการที่จะซื้อสินค้าหรือบริการมากขึ้น
          ภาพโฆษณาจึงไม่เป็นเพียงแต่ภาพที่แสดงข้อความเท่านั้น ต้องเป็นการออกแบบที่ผ่านการคิดมาอย่างรอบคอบ
          เพื่อให้ได้ประสิทธิภาพสูงสุด เพราะฉะนั้นการออกแบบจึงสำคัญมากในยุคนี้</p>
      </v-layout>
      <v-layout class="mt-12">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fgraphic%20design1.jpg?alt=media&token=6ee8f4f3-e673-4f36-9c92-430e267aa6a9">
        <img class="mr-1" src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fgraphic%20design2.jpg?alt=media&token=f6364859-2ff9-4f3d-8869-719af66a7f29">
        <img src="https://firebasestorage.googleapis.com/v0/b/secondscreativeweb.appspot.com/o/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A2%E0%B8%81%20our%20service%2Fgraphic%20design3.jpg?alt=media&token=7f385531-000e-4799-b46c-9d09f4048ce6">
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
}
</script>

<style>
#graphic-design {
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
}
#graphic-design h1 {
  font-size: 2.5em;
  line-height: 100%;
}
#graphic-design h2 {
  font-size: 1.5em;
  line-height: 100%;
  font-weight: 400;
}
#graphic-design p {
  width: 500px;
  font-size: 1.1em;
  color: #757575;
  font-weight: 300;
}
#graphic-design img {
  height: 200px;
  width: 33.33%;
  object-fit: cover;
  object-position: 50% 50%;
}
</style>
