<template>
  <v-app-bar id="navbar" fixed flat dark color="transparent">
    <v-layout v-if="$vuetify.breakpoint.mdAndUp" class="pr-8 mt-12" align-center justify-space-between>
      <router-link :to="{ name: 'home' }">
        <img v-if="dialog" class="mt-5" width="150px" src="/icon/25logo-web3.png" alt="logo">
        <img v-else-if="currentRouteName === 'home' || currentRouteName === 'index'" class="mt-5" width="150px" src="/25logo-web2.png" alt="logo">
        <img v-else class="mt-5" width="150px" src="/icon/25logo-web3.png" alt="logo">
      </router-link>

      <a v-if="dialog" class="mt-4">
        <img @click="$emit('openDialog')" class="mr-1" width="40px" src="/icon/cross.svg" alt="logo">
      </a>
      <a v-else-if="currentRouteName === 'home' || currentRouteName === 'index'" class="mt-4">
        <img @click="$emit('openDialog')" width="50px" src="/icon/white-hamburger.svg" alt="logo">
      </a>
      <a v-else class="mt-4">
        <img @click="$emit('openDialog')" width="50px" src="/icon/hamburger.svg" alt="logo">
      </a>
    </v-layout>
    <v-layout v-else class="pr-2" align-center justify-space-between>
      <router-link :to="{ name: 'home' }">
        <img v-if="dialog" class="mt-5" width="80px" src="/icon/25logo-web3.png" alt="logo">
        <img v-else-if="currentRouteName === 'home' || currentRouteName === 'index'" class="mt-5" width="80px" src="/25logo-web2.png" alt="logo">
        <img v-else class="mt-5" width="80px" src="/icon/25logo-web3.png" alt="logo">
      </router-link>

      <a v-if="dialog" class="mt-6">
        <img @click="$emit('openDialog')" class="mr-1" width="25px" src="/icon/cross.svg" alt="logo">
      </a>
      <a v-else-if="currentRouteName === 'home' || currentRouteName === 'index'" class="mt-4">
        <img @click="$emit('openDialog')" width="30px" src="/icon/white-hamburger.svg" alt="logo">
      </a>
      <a v-else class="mt-4">
        <img @click="$emit('openDialog')" width="30px" src="/icon/hamburger.svg" alt="logo">
      </a>
    </v-layout>
  </v-app-bar>
</template>

<script>
export default {
  props: ['dialog'],
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    },
    test () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  watch: {
  }
}
</script>

<style>
</style>
