<template>
  <v-flex v-if="$vuetify.breakpoint.mdAndUp" id="scrollContainer">
    <section class="child">
      <home1/>
    </section>
    <section class="child">
      <home2/>
    </section>
    <section class="child">
      <home3/>
    </section>
    <section class="child">
      <home4/>
    </section>
    <section class="child">
      <home5/>
    </section>
    <section class="child">
      <home6/>
    </section>
  </v-flex>
  <v-flex id="scrollContainer-xs" v-else>
    <section>
      <home1Xs/>
    </section>
    <section>
      <home21Xs/>
    </section>
    <section>
      <home22Xs/>
    </section>
    <section>
      <home3Xs/>
    </section>
    <section>
      <home4Xs/>
    </section>
    <section>
      <home5Xs/>
    </section>
    <section>
      <home6Xs/>
    </section>
  </v-flex>
</template>

<script>
import home1 from '@/components/Home1'
import home2 from '@/components/Home2'
import home3 from '@/components/Home3'
import home4 from '@/components/Home4'
import home5 from '@/components/Home5'
import home6 from '@/components/Home6'

import home1Xs from '@/components/Home-xs/Home1'
import home21Xs from '@/components/Home-xs/Home2-1'
import home22Xs from '@/components/Home-xs/Home2-2'
import home3Xs from '@/components/Home-xs/Home3'
import home4Xs from '@/components/Home-xs/Home4'
import home5Xs from '@/components/Home-xs/Home5'
import home6Xs from '@/components/Home-xs/Home6'
export default {
  data () {
    return {
      window: {
        width: 0,
        height: 0
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  computed: {
    height () {
      return this.window.height
    }
  },
  watch: {
  },
  methods: {
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }
  },
  components: {
    home1,
    home2,
    home3,
    home4,
    home5,
    home6,
    home1Xs,
    home21Xs,
    home22Xs,
    home3Xs,
    home4Xs,
    home5Xs,
    home6Xs
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}
#scrollContainer {
  max-height: 100vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
}
.child {
  scroll-snap-align: center;
  height: 100vh;
}
#scrollContainer-xs {
  max-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
</style>
