<template>
  <v-layout id="contact-us" ref="contactUs" fill-height justify-center>
    <v-layout :style="{ 'margin-top' : $vuetify.breakpoint.mdAndUp ? '20vh' : '70px' }" column align-center>
      <v-card max-width="500px" class="my-0 py-0" flat width="85%">
        <h1 :style="{ 'font-size' : '2em' }">CONTACT US</h1>
        <v-subheader class="px-0">สิ่งที่คุณอยากให้เราช่วยออกแบบ</v-subheader>
        <v-textarea
          value=""
          class="round"
          solo
          hide-details
          v-model="form.requirement"
          flat
        ></v-textarea>
        <v-subheader class="px-0 mt-3">จุดประสงค์ของงานที่คุณต้องการให้ออกแบบ<br>(สามารถเลือกได้มากกว่า 1) (โปรดระบุ)*</v-subheader>
        <v-layout>
          <v-flex xs6>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="สร้างยอดขาย"
              v-model="form.objectives"
              value="สร้างยอดขาย"
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="สร้างการรับรู้แบรนด์"
              v-model="form.objectives"
              value="สร้างการรับรู้แบรนด์"
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="สร้าง Engagement"
              v-model="form.objectives"
              value="สร้าง Engagement"
            ></v-checkbox>
          </v-flex>
          <v-flex xs6>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="สร้าง Brand"
              v-model="form.objectives"
              value="สร้าง Brand"
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="สร้างการจดจำ"
              v-model="form.objectives"
              value="สร้างการจดจำ"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-checkbox
            class="mt-0"
            hide-details
            color="#EC4D37"
            label="อื่นๆ (โปรดระบุ)"
            v-model="otherStatus"
            :value="other"
          ></v-checkbox>
          <v-text-field
            class="pa-0 ma-0 ml-2"
            hide-details
            v-model="other"
          ></v-text-field>
        </v-layout>
        <v-subheader class="px-0">Email (โปรดระบุ)*</v-subheader>
        <v-text-field
          class="py-0 ma-0 round"
          solo
          hide-details
          v-model="form.email"
          flat
        ></v-text-field>
      </v-card>
      <v-card max-width="500px" class="my-0 py-0" flat width="85%">
        <v-subheader class="px-0">เบอร์ติดต่อ (โปรดระบุ)*</v-subheader>
        <v-text-field
          class="py-0 ma-0 round"
          solo
          hide-details
          type="number"
          v-model="form.phone"
          flat
        ></v-text-field>
        <v-subheader class="px-0 mt-5">บริการของเราที่คุณสนใจ (สามารถเลือกได้มากกว่า 1) <br>(โปรดระบุ)*</v-subheader>
        <v-layout>
          <v-flex xs6>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="Brand Identity"
              v-model="form.services"
              value="Brand Identity"
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="Website Design"
              v-model="form.services"
              value="Website Design"
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="Application Design"
              v-model="form.services"
              value="Application Design"
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="Content Design"
              v-model="form.services"
              value="Content Design"
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="Graphic Design"
              v-model="form.services"
              value="Graphic Design"
            ></v-checkbox>
          </v-flex>
          <v-flex>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="Product Photography"
              v-model="form.services"
              value="Product Photography"
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="VDO Production"
              v-model="form.services"
              value="VDO Production"
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="All Designs"
              v-model="form.services"
              value="All Designs"
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              hide-details
              color="#EC4D37"
              label="Other"
              v-model="form.services"
              value="Other"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-btn @click="submit()" class="my-5" color="#EC4D37" dark block>submit</v-btn>
      </v-card>
      <!-- <v-layout style="width: 100%" v-if="$vuetify.breakpoint.smAndUp" align-end justify-space-between class="px-12 pt-12">
        <p class="contact">Start a Conversation<br><span style="font-size: 1.2em; text-decoration: underline;">aksika.a@25secondscreative.com</span></p>
      </v-layout>
      <v-layout style="width: 100%" v-else column class="px-5 mt-5">
        <p class="contact" style="line-height: 130%;">Start a Conversation<br><span style="font-size: 1.2em; text-decoration: underline;">aksika.a@25secondscreative.com</span></p>
      </v-layout> -->

      <v-layout style="width: 100%; background: #EC4D37;" :style="!isOverflow ? 'position: absolute; bottom: 0': ''" v-if="$vuetify.breakpoint.smAndUp" justify-space-around class="contact">
        <v-card color="transparent" flat class="pa-5 ma-0">
          <p class="contact mb-0" style="line-height: 130%; color: white;">บริษัท ทเวนตี้ไฟว์เซคคันด์ครีเอทีฟ จำกัด<br>
            232/18 หมู่บ้าน สัมมากร หมู่ที่ 3 ซอยจี 12
            ถนนรามคำแหง แขวงสะพานสูง เขตสะพานสูง
            กรุงเทพมหานคร 10240
          </p>
        </v-card>
        <v-card color="transparent" flat class="pa-5 my-0">
          <p class="mb-0" style="font-size: 0.9em; color: white;">Email : aksika.a@25secondscreative.com</p>
          <p class="mb-0" style="font-size: 0.9em; color: white;">Tel : 082-3329273</p>
        </v-card>
      </v-layout>
      <v-layout style="width: 100%; background: #EC4D37" v-else column class="px-5 py-3 mt-1">
        <p class="contact mb-1" style="line-height: 130%; color: white;">บริษัท ทเวนตี้ไฟว์เซคคันด์ครีเอทีฟ จำกัด<br>
          232/18 หมู่บ้าน สัมมากร หมู่ที่ 3 ซอยจี 12<br>
          ถนนรามคำแหง แขวงสะพานสูง เขตสะพานสูง<br>
          กรุงเทพมหานคร 10240
        </p>
        <p class="mb-0" style="font-size: 0.9em; color: white;">Email : aksika.a@25secondscreative.com</p>
        <p class="mb-0" style="font-size: 0.9em; color: white;">Tel : 082-3329273</p>
      </v-layout>
    </v-layout>
    <loading :active.sync="loading"
      background-color="#000000"
      color="#263238"
      :height="120"
      :width="120"
      :is-full-page="true">
    </loading>
  </v-layout>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import firebase from 'firebase/app'
export default {
  data () {
    return {
      form: {
        requirement: '',
        objectives: [],
        email: '',
        phone: '',
        services: []
      },
      otherStatus: false,
      other: '',
      loading: false,
      isOverflow: true
    }
  },
  mounted () {
    const element = this.$refs.contactUs
    this.isOverflow = element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth
  },
  watch: {
    other () {
      if (this.other.length) {
        this.otherStatus = true
      } else {
        this.otherStatus = false
      }
    }
  },
  methods: {
    async submit () {
      this.loading = true
      if (this.other.length) {
        this.form.objectives.push(this.other)
      }
      this.form.timestamp = new Date()
      let db = firebase.firestore()
      await db.collection('potentialCustomers').add(this.form)
      this.$router.push({ name: 'home' })
    }
  },
  computed: {
  },
  components: {
    Loading
  }
}
</script>

<style>
body, html {
  height:100%;
  margin:0;
}
#contact-us {
  position: relative;
  background-color: white;
  overflow-y: auto;
  min-height: 100vh;
  max-height: 100vh;
  -webkit-overflow-scrolling: touch;
}
#contact-us h1 {
  font-weight: 500;
  line-height: 100%;
  font-size: 1.7em;
  color: black;
  width: 85%;
}
#contact-us .v-card {
  margin-top: 10vh;
}
#contact-us .round.v-text-field > .v-input__control > .v-input__slot {
  border-style: dotted;
  border-width: 1px;
  border-radius: 3px;
}
#contact-us .v-input--selection-controls.v-input .v-label {
  font-size: 14px;
  clear: both;
  float: left;
}
#contact-us .contact {
  font-size: 0.9em;
}
#contact-us .v-textarea textarea {
  font-size: 13px;
  margin-top: 0px;
}
#contact-us .v-text-field input {
  font-size: 13px;
}
</style>
